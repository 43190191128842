import { yupResolver } from '@hookform/resolvers/yup'
import { IconChevronLeft } from '@tabler/icons'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import { Anchor, Group, Title } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { PasswordInputController, TextInputController } from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'
import { authLoginPA } from '@/features/auth/store'
import { ROLE } from '@/features/user'
import { useNotify } from '@/hooks'
import { useAppDispatch } from '@/store'
import { FormTypes } from '@/types'
import { ValidationsUtils } from '@/utils'
import useStyles from './LoginForm.styles'

export type FormValues = {
  email: string
  password: string
  remember: boolean
}

interface IProps {
  onSubmit: (values: FormValues) => Promise<void>
  onVerifyEmail: (email: string) => Promise<void>
  onApprovedInfo?: () => void
  onGoRecover?: () => void
  onGuest?: () => void
  hideForm?: boolean
  fromPopup?: boolean
}

const passwordMinLength = 8

export const LoginForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { showNotification } = useNotify()
  const dispatch = useAppDispatch()
  const { i18n } = useTranslation()
  const { confirmedBookingWithoutUser } = useSelector((state: any) => state.booking.checkout)

  const location = useLocation()
  const {
    appState: { mobileView },
  } = useAppState()

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
      remember: true,
    },
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().email('field.error.invalid').required('field.error.required'),
        password: Yup.string()
          .required('field.error.required')
          .min(passwordMinLength, 'field.error.password.length'),
      })
    ),
  })

  useEffect(() => {
    loginAfterSocialLogin()
  }, [])

  const [alertError, setAlertError] = useState<FormTypes.ValidationErrors | null>(null)
  const [submittedEmail, setSubmittedEmail] = useState<string | null>(null)

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setAlertError(null)
    setSubmittedEmail(data.email)
    try {
      await props.onSubmit(data)
    } catch (err) {
      const serverError = err as FormTypes.ValidationErrors
      setAlertError(serverError)
      ValidationsUtils.setServerSideErrors(serverError?.errors, setError)

      if (serverError && serverError?.errors?.account_not_approved) {
        props.onApprovedInfo && props.onApprovedInfo()
      }
    }
  }

  const onSendVerifyEmail = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setAlertError(null)
    clearErrors()

    if (submittedEmail) {
      try {
        await props.onVerifyEmail(submittedEmail)
        showNotification({
          type: 'success',
          message: t('confirmation-email-sent'),
        })
      } catch (err) {
        const serverError = err as FormTypes.ValidationErrors
        const message = serverError?.message || t('error')
        showNotification({
          type: 'error',
          message,
        })
      }
    }
  }

  const handleGuest = async () => {
    if (props.onGuest) props.onGuest()
  }
  const handleGoogleLogin = async () => {
    let role = window.location.pathname.split('/').pop() || ROLE.Client
    if (role != ROLE.Client && role != ROLE.Babysitter) role = ROLE.Client
    if (role === ROLE.Client && confirmedBookingWithoutUser)
      window.location.href =
        process.env.REACT_APP_BASE_URL +
        `auth/google/?role=${role}&bookingId=${confirmedBookingWithoutUser.id}`
    else window.location.href = process.env.REACT_APP_BASE_URL + `auth/google/?role=${role}`
  }

  const loginAfterSocialLogin = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const tokenFromQueryParam = urlSearchParams.get('token')
    const signupFromQueryParam = urlSearchParams.get('signup')

    if (tokenFromQueryParam) {
      // Dispatch login action with the token from the query parameter
      await dispatch(
        authLoginPA.request({
          token: tokenFromQueryParam,
          signup: signupFromQueryParam ? signupFromQueryParam : undefined,
          language: i18n.language,
          booking_id: confirmedBookingWithoutUser?.id,
        })
      )
    }
  }

  return (
    <div className={props.fromPopup ? classes.wrapPopup : classes.wrap}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!props.hideForm && (
          <div>
            {alertError && (
              <Alert type={'error'} mb={'sm'}>
                {alertError?.message || t('error')}
              </Alert>
            )}

            {alertError && alertError?.errors?.unverified_email && (
              <Title order={6} my={'md'}>
                <Anchor
                  component={'a'}
                  href={'#'}
                  onClick={(e: React.MouseEvent<HTMLElement>) => onSendVerifyEmail(e)}
                >
                  {t('send_confirmation_email_to')}
                </Anchor>
                {` ${submittedEmail}`}
              </Title>
            )}

            <TextInputController
              control={control}
              name={'email'}
              id={'email'}
              size={mobileView ? 'md' : 'lg'}
              label={t('email')}
              placeholder={t('email')}
              mb={'md'}
            />

            <PasswordInputController
              control={control}
              name={'password'}
              id={'password'}
              size={mobileView ? 'md' : 'lg'}
              label={t('password')}
              placeholder={t('password')}
              mb={'md'}
              translateParams={{ count: passwordMinLength }}
            />

            <Title order={5} align={'right'}>
              {props.fromPopup ? (
                <Anchor onClick={props.onGoRecover}>{t('forgot_password')}</Anchor>
              ) : (
                <Anchor component={Link} to={'/forgot-password'} state={{ from: location }}>
                  {t('forgot_password')}?
                </Anchor>
              )}
            </Title>

            <Button
              mt={'lg'}
              fullWidth
              color={mobileView ? 'primary' : 'secondary'}
              type={'submit'}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {t('login')}
            </Button>
          </div>
        )}
        <div className={mobileView && props.hideForm ? 'mt-2' : 'mt-10'}>
          <div className="relative">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-200 horizontal-line" />
            </div>
            <div className="relative flex justify-center text-sm font-medium leading-6">
              <span className="bg-white px-6 text-gray-900">{t('or_continue_with')}</span>
            </div>
          </div>
          <div className={mobileView ? 'mt-3 flex justify-content-center' : 'mt-6 flex gap-4'}>
            <a
              onClick={handleGoogleLogin}
              className={
                props.fromPopup || !mobileView
                  ? 'flex cursor-pointer no-underline w-100 items-center justify-center gap-3 rounded-xl bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50 focus-visible:ring-transparent border border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                  : 'flex cursor-pointer no-underline items-center justify-center gap-3 rounded-lg bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50 focus-visible:ring-transparent border border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
              }
            >
              <svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                <path
                  d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                  fill="#EA4335"
                />
                <path
                  d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                  fill="#4285F4"
                />
                <path
                  d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                  fill="#FBBC05"
                />
                <path
                  d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                  fill="#34A853"
                />
              </svg>
              {!mobileView && (
                <span className="text-sm font-semibold leading-6 no-underline">Google</span>
              )}
            </a>
          </div>
          {/*props.hideForm && props.fromPopup && (
            <Button mt={'lg'} fullWidth color="dark" radius={32} onClick={handleGuest}>
              {t('guest')}
            </Button>
          )*/}
        </div>
      </form>
    </div>
  )
}
