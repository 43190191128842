import { createSlice } from '@reduxjs/toolkit'

interface IState {
  booking: any
  confirmedBookingWithoutUser: any
}

const initialState: IState = {
  booking: null,
  confirmedBookingWithoutUser: null,
}

const checkoutStateSlice = createSlice({
  name: 'checkoutState',
  initialState,
  reducers: {
    setBooking(state, action) {
      state.booking = action.payload
    },
    setConfirmedBookingWithoutUser(state, action) {
      state.confirmedBookingWithoutUser = action.payload
    },
  },
})

/**
 * @namespace
 * @property {function} windowFocus
 */

export const checkoutState = checkoutStateSlice.actions

export default checkoutStateSlice.reducer
