import { yupResolver } from '@hookform/resolvers/yup'
import { AxiosResponse } from 'axios'
import { useEffect, useRef, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Group } from '@mantine/core'
import { Alert, Button, ContentCard } from '@/components/Elements'
import { TextInputController } from '@/components/FormElements'
import { useAppState, useEvents } from '@/features/app/hooks'
import { useAuth } from '@/features/auth'
import useStyles from '@/features/booking/components/Booking/BookingContainer/BookingWizardForm/BookingWizardForm.styles'
import {
  ageMean,
  mapSubmitValuesCareDetails,
} from '@/features/booking/components/Booking/BookingContainer/BookingWizardForm/helpers'
import { getValidationSchema } from '@/features/booking/components/Booking/BookingContainer/BookingWizardForm/validation'
import { bookingsAPI } from '@/features/bookings/api'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { useBookingOptions } from '@/features/bookings/hooks'
import { TAG_MANAGER_EVENT } from '@/features/tag-manager/consts/event'
import { useUser } from '@/features/user'
import { useFormSubmit, useNotify } from '@/hooks'
import { ChildrenFormSection, InfoFormSection } from './FormSections'

interface IProps {
  initialValuesDetails: { general: object }
  initialValuesBooking: { general: object; timetable: object }
}
export const CareDetailsForm = ({ initialValuesDetails, initialValuesBooking }: IProps) => {
  const { classes } = useStyles()
  const { showNotification } = useNotify()
  const {
    appState: { mobileView },
  } = useAppState()

  const { t } = useTranslation()
  const { user } = useUser()
  const { isLoggedIn } = useAuth()
  const { getType } = useBookingOptions()
  const navigate = useNavigate()
  const events = useEvents()
  const { booking } = useSelector((state: any) => state.booking.checkout)
  const viewport = useRef<HTMLDivElement>()
  const canChangePageRef = useRef<boolean>(false)

  const scrollToTop = () => {
    if (viewport?.current) {
      viewport.current.scrollTo({ top: 0 })
    } else {
      window.scrollTo(0, 0)
    }
  }

  useEffect(() => {
    scrollToTop()

    if (!booking || !booking?.id) {
      navigate('/book-now')
    } else {
      const alertPageChange = (e: any) => {
        if (!canChangePageRef.current) {
          e.preventDefault()
          e.returnValue = ''
          return
        }
      }

      window.addEventListener('beforeunload', alertPageChange)

      const type = booking?.type
      if (type === BOOKING_TYPE.ONETIME || type === BOOKING_TYPE.REGULAR) {
        const total = booking.total_amount / 100
        const bookingId = booking.id
        if (isLoggedIn) {
          //if (booking?.payment_confirmed) {
          const purchaseType =
            type === BOOKING_TYPE.ONETIME
              ? TAG_MANAGER_EVENT.PURCHASE_OCASIONAL
              : TAG_MANAGER_EVENT.PURCHASE_REGULAR

          const userHasntBookings = user && !user.has_bookings
          try {
            events.actions.purchaseType(
              purchaseType,
              total.toFixed(2),
              bookingId,
              userHasntBookings,
              'checkout_first'
            )
          } catch (e) {
            console.error('Error sending careDetails event')
            console.error(e)
          }
          try {
            events.actions.purchaseEcommerce(total.toFixed(2), {
              transaction_id: bookingId,
              coupon: purchaseType,
              affiliation: purchaseType,
              total: total,
              value: total,
              currency: 'EUR',
              items: [
                {
                  item_name: purchaseType,
                },
              ],
            })
          } catch (e) {
            console.error('Error sending careDetails event')
            console.error(e)
          }
          //}
        } else {
          events.actions.careDetails({
            Type: type,
            Value: total,
            Booking: bookingId,
            AgeMean: ageMean(booking.booking_children),
          })
        }
      }

      if (booking.type == BOOKING_TYPE.ANIMATION) {
        events.actions.submissionAnimation({
          Booking: booking.id,
        })
      }
      if (booking.type == BOOKING_TYPE.PERMANENT_NANNY) {
        events.actions.submissionNanny({
          Booking: booking.id,
        })
      }
      return () => {
        window.removeEventListener('beforeunload', alertPageChange)
      }
    }
  }, [])

  const defaultValues = {
    booking_children: [],
    primary_language_id: null,
    secondary_language_id: null,
    has_special_needs: false,
    phone: null,
    address: null,
    // special_needs: '', // hidden field, depend on 'has_special_needs'
    //other_info: '',
    care_expectations: '',

    ...initialValuesBooking?.general,
    ...initialValuesDetails?.general,
  }

  // console.log('booking:' + JSON.stringify(initialValuesBooking?.general))
  // console.log('details:' + JSON.stringify(initialValuesDetails?.general))
  // console.log('defaultValues:' + JSON.stringify(defaultValues))

  const [loading, setLoading] = useState(false)
  const [alertError, setAlertError] = useState<string | null>(null)

  const currentValidationSchema = getValidationSchema({ step: 2 })

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(currentValidationSchema),
  })

  const {
    handleSubmit,
    trigger,
    setError,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = methods

  const saveCareDetails = async (formData?: any): Promise<AxiosResponse> => {
    const params = {
      ...formData,
      booking_id: booking?.id,
    }
    return await bookingsAPI.saveCareDetails(params)
  }

  const onSubmitCareDetails = async (values: any) => {
    console.log('onSubmitCareDetails:' + booking?.type)
    try {
      setAlertError(null)
      setLoading(true)

      const response: AxiosResponse = await saveCareDetails(values)

      canChangePageRef.current = true
      if (booking?.type !== BOOKING_TYPE.ONETIME && booking?.type !== BOOKING_TYPE.REGULAR) {
        navigate('/booking/regular/confirmed', { replace: true })
      } else {
        if (isLoggedIn) {
          navigate('/booking/confirmed', { replace: true })
        } else {
          navigate('/booking/' + getType(booking.type).path + '/checkout', { replace: true })
        }
      }
    } catch (e) {
      console.error(e)
      setAlertError(t('booking_save_error'))
    } finally {
      setLoading(false)
    }
  }

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: onSubmitCareDetails,
    setError,
  })

  const onSubmit: SubmitHandler<any> = async (data) => {
    const isStepValid = await trigger()
    if (isStepValid) {
      try {
        const values = {
          ...mapSubmitValuesCareDetails(data),
        }
        await onFormSubmit(values)
        // eslint-disable-next-line
      } catch (err) {
        showNotification({
          type: 'error',
          message: t('error_submitting_request'),
        })
      }
    } else {
      showNotification({
        type: 'error',
        message: t('please_fill_all_required_fields'),
      })
    }
  }
  const renderFooter = () => {
    return (
      <div className={'flex justify-between items-center'}>
        <div></div>

        <Group>
          <Button loading={isSubmitting} onClick={handleSubmit(onSubmit)}>
            {t(isLoggedIn ? 'save' : 'booking.continue')}
          </Button>
        </Group>
      </div>
    )
  }

  return (
    booking && (
      <FormProvider {...methods}>
        <form className={mobileView ? '' : 'h-full'}>
          <ContentCard
            title={t('care_details')}
            footer={renderFooter()}
            scrollable={!mobileView}
            fullHeight={!mobileView}
            viewport={viewport}
            className={classes.cardWrapper}
          >
            <div className={classes.wrapper}>
              {alertError && (
                <Alert type={'error'} mb={'sm'}>
                  {alertError}
                </Alert>
              )}
              {submitError && (
                <Alert type={'error'} mb={'sm'}>
                  {submitError?.message || t('error')}
                </Alert>
              )}
              {!booking?.is_hotel && (
                <TextInputController
                  control={control}
                  name={'address'}
                  id={'address'}
                  size={mobileView ? 'md' : 'lg'}
                  label={t('address')}
                  placeholder={t('address')}
                  mb={'md'}
                  styles={
                    mobileView
                      ? {
                          label: {
                            fontWeight: 'bold',
                            fontSize: '16px !important',
                          },
                        }
                      : {}
                  }
                />
              )}

              <ChildrenFormSection
                boldTitle={true}
                animation={booking?.type == BOOKING_TYPE.ANIMATION}
              />

              {/*<div className={mobileView ? 'pt-3' : 'mt-3 pt-3'}>
              <LanguagesFormSection boldTitle={true} />
            </div>*/}

              <div className={mobileView ? 'pt-3' : 'mt-3 pt-3'}>
                <InfoFormSection />
              </div>
            </div>
            {alertError && (
              <Alert type={'error'} mb={'sm'}>
                {alertError}
              </Alert>
            )}
          </ContentCard>
        </form>
      </FormProvider>
    )
  )
}
