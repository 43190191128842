import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentIntentResult, StripePaymentElementOptions } from '@stripe/stripe-js'
import classNames from 'classnames'
import { FormEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Anchor, Space, Tooltip } from '@mantine/core'
import { Checkbox as MantineCheckbox, Text } from '@mantine/core'
import { Button } from '@/components/Elements'
import { EXTERNAL_LINK } from '@/const/core'
import { useAppState } from '@/features/app/hooks'
import { useAuth } from '@/features/auth'
import { checkoutState } from '@/features/booking/store/checkout.slice'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { getType } from '@/features/bookings/helpers/booking/type'
import { BookingModel } from '@/features/bookings/types/models'
import { useNotify } from '@/hooks'
import useStyles from './CheckoutForm.styles'

interface IProps {
  booking: BookingModel
  paymentId: any
}
export default function CheckoutForm({ booking, paymentId }: IProps) {
  const { t } = useTranslation()
  const { isLoggedIn } = useAuth()
  const { showNotification } = useNotify()
  const dispatch = useDispatch()

  const stripe = useStripe()
  const elements = useElements()
  const [message, setMessage] = useState<any>(null)
  const [accepted, setAccepted] = useState(false)
  const [showAcceptError, setShowAcceptedError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { classes } = useStyles()
  const navigate = useNavigate()
  const canChangePageRef = useRef<boolean>(false)

  const {
    appState: { mobileView },
  } = useAppState()

  const goNext = () => {
    canChangePageRef.current = true
    if (isLoggedIn) {
      navigate('/booking/' + getType(booking.type).path + '/care-details', { replace: true })
    } else {
      navigate('/booking/confirmed', { replace: true })
    }
  }

  useEffect(() => {
    const alertPageChange = (e: any) => {
      if (!canChangePageRef.current) {
        e.preventDefault()
        e.returnValue = ''
        return
      }
    }

    window.addEventListener('beforeunload', alertPageChange)

    return () => {
      window.removeEventListener('beforeunload', alertPageChange)
    }
  }, [])

  const submit = async (e: FormEvent) => {
    e.preventDefault()

    if (!accepted) {
      showNotification({
        type: 'error',
        message: t('terms_must_be_accepted'),
      })
      setShowAcceptedError(true)
      return
    } else {
      setShowAcceptedError(false)
    }
    if (isLoading || !stripe || !elements) {
      console.log('stripe: ' + !stripe + ' elements ' + !elements)
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    try {
      const { error, paymentIntent }: PaymentIntentResult = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.origin}/booking/confirmed`,
        },
        redirect: 'if_required',
      })

      if (error?.type === 'card_error' || error?.type === 'validation_error') {
        setMessage(error?.message)
      } else {
        const alreadyPayed = paymentIntent?.status == 'succeeded'
        if (paymentIntent?.status == 'succeeded') {
          //await bookingsAPI.setBookingConfirmed(booking.id, paymentId)
        }
        await dispatch(checkoutState.setBooking({ ...booking, payment_confirmed: alreadyPayed }))
        goNext()
        //setOcasionalPaymentDone(true)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }
  const renderVat = (priceAnalysis: any) => {
    const priceWithoutVAT = (booking.total_amount || 0) / (1 + priceAnalysis['Vat'] / 100)

    const vatAmount = (priceWithoutVAT / 100) * (priceAnalysis['Vat'] / 100)
    return vatAmount.toFixed(2)
  }

  const renderTooltip = () => {
    const priceAnalysis = booking.price_analysis
    return (
      <>
        {priceAnalysis && !!priceAnalysis['Hours'] && (
          <div className="flex gap-1 font-bold">
            <span>{t('price.hours') + ': ' + priceAnalysis['Hours']} *</span>
            <span>{priceAnalysis['Amount']}€</span>
            <span>= {priceAnalysis['Price']}€</span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Festivity Price'] && (
          <div className="flex gap-1 font-bold">
            <span>{t('price.festivities') + ': ' + priceAnalysis['Festivity Price']}€</span>
          </div>
        )}
        {/*{priceAnalysis && !!priceAnalysis['Morning Hours'] && (
          <div className="flex gap-1 font-bold">
            <span>{t('price.morning_hours') + ': ' + priceAnalysis['Morning Hours']} *</span>
            <span>{priceAnalysis['Morning Amount']}€</span>
            <span>= {priceAnalysis['Morning Price']}€</span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Festivities Price Morning'] && (
          <div className="flex gap-1 font-bold">
            <span>
              {t('price.festivities_morning') + ': ' + priceAnalysis['Festivities Price Morning']}€
            </span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Evening Hours'] && (
          <div className="flex gap-1 font-bold">
            <span>{t('price.evening_hours') + ': ' + priceAnalysis['Evening Hours']} *</span>
            <span>{priceAnalysis['Evening Amount']}€</span>
            <span>= {priceAnalysis['Evening Price']}€</span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Festivities Price Evening'] && (
          <div className="flex gap-1 font-bold">
            <span>
              {t('price.festivities_evening') + ': ' + priceAnalysis['Festivities Price Evening']}€
            </span>
          </div>
        )}*/}
        {priceAnalysis && !!priceAnalysis['Distance Price'] && (
          <div className="flex gap-1 font-bold">
            <span>
              {t('price.mileage_fee') + ': ' + priceAnalysis['Distance Price'].toFixed(2)}€
            </span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Urgency Fee'] && (
          <div className="flex gap-1 font-bold">
            <span>{t('price.urgency_fee') + ': ' + priceAnalysis['Urgency Fee']}€</span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Special Needs Fee'] && (
          <div className="flex gap-1 font-bold">
            <span>
              {t('price.special_needs_fee') + ': ' + priceAnalysis['Special Needs Amount']}€
            </span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Bonus'] && (
          <div className="flex gap-1 font-bold">
            <span>{t('price.bonus') + ': ' + priceAnalysis['Bonus'].toFixed(2)}€</span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Vat'] && (
          <div className="flex gap-1 font-bold">
            <span>{t('price.vat') + ': ' + renderVat(priceAnalysis)}€</span>
          </div>
        )}
        {priceAnalysis &&
          !!priceAnalysis['pricesPerMonth'] &&
          Object.keys(priceAnalysis['pricesPerMonth']).length > 1 && (
            <>
              <div className="flex gap-1 mt-2 font-bold">
                <span>{t('price.per_month') + ''}</span>
              </div>
              {Object.keys(priceAnalysis['pricesPerMonth']).map((key, index) => (
                <>
                  {index > 0 && (
                    <div key={index} className="flex gap-1 font-bold">
                      <span>
                        {t('month') + ' '}
                        {key + ': ' + priceAnalysis['pricesPerMonth'][key].toFixed(2)}€
                      </span>
                    </div>
                  )}
                </>
              ))}
            </>
          )}
      </>
    )
  }

  const options: StripePaymentElementOptions = {
    layout: {
      type: 'accordion',
      defaultCollapsed: false,
      radios: true,
      spacedAccordionItems: true,
    },
  }

  return (
    <form id="payment-form" onSubmit={submit}>
      <PaymentElement options={options} id="payment-element" />

      <div className={mobileView ? 'mt-3' : ' mt-3'}>
        <MantineCheckbox
          checked={accepted}
          onChange={(event) => setAccepted(event.currentTarget.checked)}
          label={
            <>
              {t('register_terms_label')}{' '}
              <Anchor component={'a'} href={EXTERNAL_LINK.TERMS_AND_CONDITIONS} target={'_blank'}>
                {t('register_terms_text')}
              </Anchor>
            </>
          }
          mb={'md'}
        />
        {showAcceptError && (
          <Text size={13} color={'red'}>
            {t('terms_must_be_accepted')}
          </Text>
        )}
        <div className="flex bg-white rounded-lg shadow-sm mb-8 p-3 gap-3">
          <div className="text-sm text-center">{t('checkout.text')}</div>
        </div>
      </div>

      <Space h={70} />
      <div
        className={classNames(
          classes.footerPriceMobile,
          'flex',
          'justify-between',
          'items-center',
          {
            'sticky-footer': mobileView,
            'sticky-footer-desktop': !mobileView,
          }
        )}
      >
        <div>
          <Tooltip
            label={renderTooltip()}
            position="top"
            //withArrow
            //arrowSize={6}
            multiline
            events={{ hover: true, touch: true, focus: false }}
          >
            <h2
              className="price-calculation-tooltip flex gap-4 items-center cursor-pointer"
              data-pr-position="top"
            >
              <span className={classes.price}>
                {t(booking.type === BOOKING_TYPE.REGULAR ? 'price.1st_month' : 'price')}
              </span>
              <i className="pi pi-info-circle"></i>
            </h2>
          </Tooltip>

          <h2
            className="price-calculation-tooltip flex gap-4 m-0 items-center cursor-pointer"
            data-pr-position="top"
          >
            <span>€{((booking.total_amount || 0) / 100).toFixed(2)}</span>
          </h2>
        </div>
        <div className="mt-4 mb-2">
          <Button loading={isLoading} disabled={!stripe || !elements} fullWidth type="submit">
            {t('pay')}
          </Button>
        </div>
      </div>
      {/* Show any error or success messages */}
      {message && (
        <div className=" text-primary-500 text-sm mt-1 font-quicksand" id="payment-message">
          {message}
        </div>
      )}
    </form>
  )
}
