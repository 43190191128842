import mixpanel from 'mixpanel-browser'

export const useMixPanel = () => {
  const env_check = process.env.REACT_APP_MIXPANEL_SEND === 'true'

  if (env_check)
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || '', {
      debug: true,
      verbose: true,
      track_pageview: false,
      persistence: 'localStorage',
    })

  const actions = {
    identify: (id: any) => {
      try {
        const customId = process.env.REACT_APP_ENV === 'staging' ? 'DEV' + id : id
        if (env_check) mixpanel.identify(customId)
      } catch (Exception) {}
    },
    track: (name: any, props: any) => {
      console.log('mix panel track:' + name + ' props:' + JSON.stringify(props))
      try {
        if (env_check) mixpanel.track(name, { ...props, Date: new Date() })
      } catch (Exception) {}
    },
    reset: () => {
      try {
        if (env_check) mixpanel.reset()
      } catch (Exception) {}
    },
    people: {
      set: (props: any) => {
        try {
          if (env_check) mixpanel.people.set(props)
        } catch (Exception) {}
      },
    },
  }
  return {
    actions,
  }
}
