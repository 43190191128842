import { IconChevronLeft } from '@tabler/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Anchor, Group, Space, Title } from '@mantine/core'
import { Alert } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { ROLE } from '@/features/user'
import { useLang } from '@/hooks'
import { authRegisterPA } from '../../../store'
import useStyles from './RegisterContainer.styles'
import { FormValues, RegisterForm } from './RegisterForm'

interface IProps {
  role: ROLE
  fromPopup?: boolean
  onGoBack?: () => void
  onLoginModalClose?: () => void
}

export const RegisterContainer = ({
  role,
  fromPopup = false,
  onGoBack,
  onLoginModalClose,
}: IProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { classes } = useStyles()
  const navigate = useNavigate()
  const { confirmedBookingWithoutUser } = useSelector((state: any) => state.booking.checkout)
  const {
    appState: { mobileView },
  } = useAppState()

  const { lang } = useLang()

  const [success, setSuccess] = useState(false)

  const handleSubmit = async (values: FormValues) => {
    setSuccess(false)
    const params = {
      ...values,
      role,
      lang,
      booking_id: confirmedBookingWithoutUser?.id,
    }
    const data = await dispatch(authRegisterPA.request(params))

    setSuccess(true)

    if (confirmedBookingWithoutUser && onLoginModalClose) onLoginModalClose()
  }

  const goToLogin = () => {
    console.log('send app_page_view register, role:' + role)

    navigate(role === ROLE.Client ? '/login/client' : '/babysitter')
  }

  return (
    <div>
      <div className={fromPopup ? classes.wrapPopup : classes.wrap}>
        {fromPopup && (
          <div className={'mb-2 flex'}>
            {role == ROLE.Client && (
              <Title order={5}>
                <Anchor onClick={onGoBack}>
                  <Group>
                    <IconChevronLeft />
                    {t('back')}
                  </Group>
                </Anchor>
              </Title>
            )}
          </div>
        )}
        <Title order={fromPopup ? 3 : 1} className={'mb-4'}>
          {role === ROLE.Client ? t('create_client_account') : t('create_babysitter_account')}
        </Title>

        {!fromPopup && (
          <div className={'mb-4 flex'}>
            <Title order={mobileView ? 5 : 6}>
              {t('already_have_account')}?{' '}
              <Anchor onClick={goToLogin}>{t('login.access_here.text')}</Anchor>
            </Title>
          </div>
        )}

        {!mobileView && success && (
          <>
            <Space h={'md'} />
            <Alert type={'success'} mb={'sm'}>
              {t('confirmation-email-sent')}!
            </Alert>
          </>
        )}
      </div>
      <RegisterForm onSubmit={handleSubmit} role={role} success={success} fromPopup={fromPopup} />
    </div>
  )
}
