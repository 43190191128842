import { AxiosError, AxiosResponse } from 'axios'
import {
  PromiseAction,
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from 'redux-saga-promise-actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { useEvents, useMixPanel } from '@/features/app/hooks'
import { checkoutState } from '@/features/booking/store/checkout.slice'
import { bookingAPI } from '@/features/bookings/api'
import { profileAPI } from '@/features/profile/api'
import { userData } from '@/features/user/store'
import { FormTypes } from '@/types'
import { authAPI } from '../../api'
import { LoginDTO } from '../../api/dto'
import { authData } from '../data/slice'
import { AUTH_LOGIN_FAILED, AUTH_LOGIN_REQUEST, AUTH_LOGIN_SUCCESS } from './actionTypes'

export const authLoginPA = createPromiseAction(
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILED
)<LoginDTO, unknown, AxiosError<FormTypes.ValidationErrors>>()

function* login(action?: PromiseAction<string, LoginDTO, any>) {
  const mixPanel = useMixPanel()
  const events = useEvents()
  //  const dispatch = useAppDispatch()

  try {
    if (action) {
      const { payload } = action

      // If the payload has a token, use it for authentication
      if (payload && payload.token) {
        yield put(authData.setData({ isLoggedIn: true, token: payload.token }))
        yield put(authLoginPA.success({}))

        const response: AxiosResponse = yield call(profileAPI.getProfile)
        const { data } = response
        mixPanel.actions.identify(data?.data.id)
        mixPanel.actions.people.set({
          $first_name: data?.data.first_name,
          $last_name: data?.data.last_name,
          $email: data?.data.email,
          $language: payload.language,
        })
        if (payload.signup && payload.signup === '1') {
          events.actions.register({
            Type: 'Google',
          })
        } else {
          events.actions.login({
            Type: 'Google',
          })
        }
        if (payload.booking_id) {
          yield put(checkoutState.setConfirmedBookingWithoutUser(null))
        }
        yield put(userData.setData(data?.data))
      } else {
        // If no token in the payload, proceed with your existing login logic
        const response: AxiosResponse = yield call(authAPI.login, payload)
        const { data } = response

        yield put(authLoginPA.success({}))
        yield put(authData.setData({ isLoggedIn: true, token: data?.data?.token }))
        const responseProfile: AxiosResponse = yield call(profileAPI.getProfile)
        mixPanel.actions.identify(responseProfile?.data.data.id)
        mixPanel.actions.people.set({
          $first_name: responseProfile?.data.data.first_name,
          $last_name: responseProfile?.data.data.last_name,
          $email: responseProfile?.data.data.email,
          $language: payload.language,
        })
        events.actions.login({
          Type: 'Email/Password',
        })
        /*
        if (payload.checkedOutBooking) {
          console.log('send booking')
          const response: AxiosResponse = yield call(bookingAPI.connectUserWithBooking, {
            userId: responseProfile?.data?.data?.data.id,
            bookingId: payload.checkedOutBooking,
          })
          yield put(checkoutState.setConfirmedBookingWithoutUser(null))
        }
*/
      }

      resolvePromiseAction(action, {})
    }
  } catch (err) {
    const error = err as AxiosError<FormTypes.ValidationErrors>

    if (!error.response) {
      throw error
    }
    if (action) rejectPromiseAction(action, error.response.data)
  }
}

export function* loginSaga() {
  yield takeEvery(authLoginPA.request, login)
}
