import { IconChevronLeft } from '@tabler/icons'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Anchor, Group, Title } from '@mantine/core'
import { useAppState } from '@/features/app/hooks'
import { checkoutState } from '@/features/booking/store/checkout.slice'
import { ROLE } from '@/features/user'
import { useAppDispatch } from '@/store'
import { authLoginPA, authVerifyEmailPA } from '../../../store'
import useStyles from './LoginContainer.styles'
import { FormValues, LoginForm } from './LoginForm'

interface IProps {
  role: ROLE
  onApprovedInfo?: () => void
  hideForm?: boolean
  backLink?: string
  fromPopup?: boolean
  onGoBack?: () => void
  onGoRecover?: () => void
  onLoginModalClose?: () => void
  onGuestClose?: () => void
}

export const LoginContainer = ({
  role,
  fromPopup = false,
  onApprovedInfo,
  onGoBack,
  onLoginModalClose,
  onGuestClose,
  hideForm,
  backLink,
  onGoRecover,
}: IProps) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { classes } = useStyles()
  const { confirmedBookingWithoutUser } = useSelector((state: any) => state.booking.checkout)
  const {
    appState: { mobileView },
  } = useAppState()

  const handleSubmit = async (values: FormValues) => {
    const response = await dispatch(
      authLoginPA.request({
        ...values,
        language: i18n.language,
        booking_id: confirmedBookingWithoutUser?.id,
      })
    )
    if (confirmedBookingWithoutUser && onLoginModalClose) onLoginModalClose()
  }

  const onVerifyEmail = async (email: string) => {
    await dispatch(authVerifyEmailPA.request({ email }))
  }

  const goToAuth = () => {
    navigate(role === ROLE.Client ? '/' : '/babysitter')
  }

  const goToRegister = () => {
    navigate(role === ROLE.Client ? '/register/client' : '/register/babysitter')
  }
  return (
    <div>
      {!hideForm && (
        <>
          <div className={'mb-2 flex'}>
            {role == ROLE.Client && (
              <Title order={5} className={fromPopup ? classes.backPopup : classes.back}>
                <Anchor onClick={fromPopup ? onGoBack : goToAuth}>
                  <Group>
                    <IconChevronLeft />
                    {t('back')}
                  </Group>
                </Anchor>
              </Title>
            )}
          </div>
          <div className={fromPopup ? classes.wrapPopup : classes.wrap}>
            <Title order={fromPopup ? 3 : 1}>{`${t('hi')}, ${t('welcome_back')}`}</Title>

            {!fromPopup && (
              <div className={'mt-4 mb-4 flex'}>
                <Title order={mobileView ? 5 : 6}>
                  {t('dont_have_account')}? <Anchor onClick={goToRegister}>{t('sign_up')}</Anchor>{' '}
                </Title>
              </div>
            )}
          </div>
        </>
      )}

      <LoginForm
        onSubmit={handleSubmit}
        onVerifyEmail={onVerifyEmail}
        onApprovedInfo={onApprovedInfo}
        hideForm={hideForm}
        fromPopup={fromPopup}
        onGoRecover={onGoRecover}
        onGuest={onGuestClose}
      />
    </div>
  )
}
