import { debounce } from 'lodash'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid, Title } from '@mantine/core'
import { ActionIconButton } from '@/components/Elements'
import { DatePickerController, FormControlActionBlock } from '@/components/FormElements'
import { TimeController } from '@/components/FormElements/Controllers/TimeInputSelectController/TimeController'
import { useAppState } from '@/features/app/hooks'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { formatTime, timeToDate } from '@/utils/date'

interface IProps {
  name: string
  control: any
  gridProps?: any
  index: number
  item: Record<'fieldId', string>
  onRemove: (index: number) => void
}

export const OnetimeRangesItem = ({ name, control, index, item, gridProps, onRemove }: IProps) => {
  const { t } = useTranslation()

  const {
    appState: { mobileView },
  } = useAppState()

  const { watch, setValue, getValues } = useFormContext()

  const watchType = watch('type')
  const watchDateEnd = watch('date_end')
  const watchTimeStart = watch(`${name}.${index}.range_time_start`)

  const calcEndDate = () => {
    const timeStart = timeToDate(getValues(`${name}.${index}.range_time_start`))
    const timeEnd = timeToDate(getValues(`${name}.${index}.range_time_end`))
    const type = getValues('type')
    if (
      timeStart &&
      type == BOOKING_TYPE.ONETIME &&
      (!timeEnd || !calcTimeDiffOk(timeStart, timeEnd))
    ) {
      setValue(
        `${name}.${index}.range_time_end`,
        formatTime(moment(timeStart).add(3, 'hours').toDate())
      )
    }
  }

  const debouncedCalcEndDate = React.useCallback(debounce(calcEndDate, 1000), [])
  useEffect(() => {
    if (watchTimeStart) {
      debouncedCalcEndDate()
    }
  }, [watchTimeStart])

  const removeRow = (index: number) => {
    onRemove(index)
  }

  const calcTimeDiffOk = (startTime: any, endTime: any) => {
    const diff = moment()
      .hour(moment(endTime).hour())
      .diff(moment().hour(moment(startTime).hour()))

    const duration = moment.duration(diff)
    return duration.asHours() >= 4 || (duration.asHours() < 0 && duration.asHours() >= -20)
  }

  const renderTitleSection = () => (
    <Title order={5} mb={'sm'} color={'primary'}>
      {t('added_date')}
    </Title>
  )

  return (
    <div key={item.fieldId} className={'flex flex-col mt-2'}>
      {renderTitleSection()}
      {watchType == BOOKING_TYPE.ONETIME ? (
        <>
          <Grid grow {...gridProps}>
            <Grid.Col span={mobileView ? 6 : 4}>
              <DatePickerController
                control={control}
                name={`${name}.${index}.range_date_start`}
                id={`${name}.${index}.range_date_start`}
                label={t('service_date')}
                size={mobileView ? 'md' : 'lg'}
                placeholder={t('service_date')}
                withinPortal
                clearable={false}
                mb={'md'}
                translateParams={{ date: t('today') }}
                minDate={new Date()}
                initialMonth={watchDateEnd || new Date()}
                styles={
                  mobileView
                    ? {
                        label: {
                          fontWeight: 'bold',
                          fontSize: '16px !important',
                        },
                      }
                    : {}
                }
              />
            </Grid.Col>

            <Grid.Col span={mobileView ? 5 : 3} />
            <Grid.Col span={mobileView ? 1 : 5}>
              <FormControlActionBlock className={mobileView ? 'mt-4' : 'mb-4'}>
                <ActionIconButton name={'remove'} onClick={() => removeRow(index)} />
              </FormControlActionBlock>
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col span={mobileView ? 6 : 4}>
              <TimeController
                control={control}
                name={`${name}.${index}.range_time_start`}
                id={`${name}.${index}.range_time_start`}
                label={t('booking.start_hour')}
                size={mobileView ? 'md' : 'lg'}
                mb={'md'}
                setValue={setValue}
                getValues={getValues}
                watch={watch}
                styles={
                  mobileView
                    ? {
                        label: {
                          fontWeight: 'bold',
                          fontSize: '16px !important',
                        },
                      }
                    : {}
                }
              />
            </Grid.Col>

            <Grid.Col span={mobileView ? 6 : 4}>
              <TimeController
                control={control}
                name={`${name}.${index}.range_time_end`}
                id={`${name}.${index}.range_time_end`}
                label={t('booking.end_hour')}
                size={mobileView ? 'md' : 'lg'}
                mb={'md'}
                className={mobileView ? 'ml-2' : ''}
                setValue={setValue}
                getValues={getValues}
                watch={watch}
                styles={
                  mobileView
                    ? {
                        label: {
                          fontWeight: 'bold',
                          fontSize: '16px !important',
                        },
                      }
                    : {}
                }
              />
            </Grid.Col>
          </Grid>
        </>
      ) : (
        <>
          <Grid grow {...gridProps} columns={13}>
            <Grid.Col span={mobileView ? 6 : 4}>
              <DatePickerController
                control={control}
                name={`${name}.${index}.range_date_start`}
                id={`${name}.${index}.range_date_start`}
                label={t('start_date')}
                size={mobileView ? 'md' : 'lg'}
                placeholder={t('start_date')}
                withinPortal
                clearable={false}
                mb={'md'}
                translateParams={{ date: t('today') }}
                minDate={new Date()}
                initialMonth={watchDateEnd || new Date()}
                styles={
                  mobileView
                    ? {
                        label: {
                          fontWeight: 'bold',
                          fontSize: '16px !important',
                        },
                      }
                    : {}
                }
              />
            </Grid.Col>

            <Grid.Col span={mobileView ? 6 : 4}>
              <TimeController
                control={control}
                name={`${name}.${index}.range_time_start`}
                id={`${name}.${index}.range_time_start`}
                label={t('from')}
                size={mobileView ? 'md' : 'lg'}
                mb={'md'}
                setValue={setValue}
                getValues={getValues}
                watch={watch}
                styles={
                  mobileView
                    ? {
                        label: {
                          fontWeight: 'bold',
                          fontSize: '16px !important',
                        },
                      }
                    : {}
                }
              />
            </Grid.Col>

            <Grid.Col span={mobileView ? 1 : 5} />
          </Grid>

          <Grid columns={13}>
            <Grid.Col span={mobileView ? 6 : 4}>
              <DatePickerController
                control={control}
                name={`${name}.${index}.range_date_end`}
                id={`${name}.${index}.range_date_end`}
                size={mobileView ? 'md' : 'lg'}
                label={t('end_date')}
                placeholder={t('end_date')}
                mb={'md'}
                withinPortal
                clearable={false}
                translateParams={{ date: t('start_date') }}
                minDate={new Date()}
                initialMonth={watchDateEnd || new Date()}
                styles={
                  mobileView
                    ? {
                        label: {
                          fontWeight: 'bold',
                          fontSize: '16px !important',
                        },
                      }
                    : {}
                }
              />
            </Grid.Col>

            <Grid.Col span={mobileView ? 6 : 4}>
              <TimeController
                control={control}
                name={`${name}.${index}.range_time_end`}
                id={`${name}.${index}.range_time_end`}
                label={t('to')}
                size={mobileView ? 'md' : 'lg'}
                mb={'md'}
                className={mobileView ? 'ml-2' : ''}
                setValue={setValue}
                getValues={getValues}
                watch={watch}
                styles={
                  mobileView
                    ? {
                        label: {
                          fontWeight: 'bold',
                          fontSize: '16px !important',
                        },
                      }
                    : {}
                }
              />
            </Grid.Col>

            <Grid.Col span={mobileView ? 1 : 5}>
              <FormControlActionBlock className={mobileView ? 'mt-4' : 'mb-4'}>
                <ActionIconButton name={'remove'} onClick={() => removeRow(index)} />
              </FormControlActionBlock>
            </Grid.Col>
          </Grid>
        </>
      )}
    </div>
  )
}
