import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FormUtils } from '@/utils'

export interface HtmlTimeInputProps {
  translateParams?: object
  meta?: any
  name: any
  className?: any
  label: string
  setValue: any
  watch: any
}

export const HtmlTimeInput = ({
  meta,
  name,
  translateParams,
  className,
  setValue,
  watch,
  label,
}: HtmlTimeInputProps) => {
  const { t } = useTranslation()
  const value = watch(name)
  const inputRef = useRef<HTMLInputElement>(null)

  const { error } = meta

  const showError = Boolean(error)

  const getErrorMessage = () => {
    return FormUtils.getErrorMessage(error, {
      t,
      translateParams: translateParams,
    })
  }
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const time = '' + inputRef.current?.value || ''
    setValue(name, '' + time)
  }

  return (
    <div className={className} style={{ display: 'flex', flexDirection: 'column' }}>
      <label className="mantine-InputWrapper-label mantine-Textarea-label phone-widget-title">
        {label}
      </label>
      <div className="mantine-InputWrapper-root mantine-TextInput-root">
        <div className="mantine-Input-wrapper mantine-TextInput-wrapper">
          <input
            type="time"
            value={value}
            onChange={onInputChange}
            ref={inputRef}
            className={
              showError
                ? 'mantine-Input-input mantine-TextInput-input my-time-input my-time-invalid'
                : 'mantine-Input-input mantine-TextInput-input my-time-input'
            }
          />
        </div>
      </div>
      {showError && (
        <label className="mantine-Text-root my-time-error mantine-TextInput-error mt-1">
          {getErrorMessage()}
        </label>
      )}
    </div>
  )
}
