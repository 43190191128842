import { useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Center, Group, Space, Text, Title } from '@mantine/core'
import cat from '@/assets/images/illustrations/cat.png'
import { Alert, Button, MegaTagTitle } from '@/components/Elements'
import { useAppState, useEvents } from '@/features/app/hooks'
import { AccountNotApprovedInfo } from '@/features/auth/components/Elements'
import { LoginContainer } from '@/features/auth/components/Loging'
import { ROLE } from '@/features/user'
import { usePopup, useQuery } from '@/hooks'
import { SectionReviews } from '../../components/SectionReviews'
import useStyles from './Auth.styles'

interface IProps {
  role: ROLE
}

interface IAlert {
  type: string
  message: string
}

export const Auth = ({ role }: IProps) => {
  const { t } = useTranslation()
  const event = useEvents()

  const navigate = useNavigate()
  const query = useQuery()
  const { classes } = useStyles()
  const events = useEvents()

  const [alert, setAlert] = useState<IAlert | null>(null)

  const { visible, open, close } = usePopup()

  const {
    appState: { mobileView },
  } = useAppState()

  const setAlertMessage = (data: IAlert | null = null) => {
    setAlert(data)
  }

  const historyReplace = () => {
    navigate(
      {
        search: '',
      },
      { replace: true }
    )
  }

  const onApprovedInfo = () => {
    open({})
  }

  useEffect(() => {
    if (query.get('verified') === '1') {
      setAlertMessage({
        message: 'email_verification.success',
        type: 'success',
      })

      historyReplace()

      if (role === ROLE.Babysitter) {
        onApprovedInfo()
      }
    }
    if (role === ROLE.Client) {
      event.actions.auth({})
    }
  }, [])

  /*const handleNew = () => {
    events.actions.bookingWithoutLogin()

    navigate('/book-now')
  }*/
  const goToLogin = () => {
    navigate('/login/client')
  }
  const goToRegister = () => {
    navigate('/register/client')
  }

  return (
    <div className={classes.wrap}>
      <MegaTagTitle title={role === ROLE.Client ? 'client_login' : 'babysitter_login'} />

      {alert?.message && (
        <Alert type={'success'} mb={'md'}>
          {t(alert.message)}
        </Alert>
      )}

      <Group>
        <Center className="w-100">
          <Title order={2} className={'text-center mb-2'}>
            {ReactHtmlParser(t('auth.message'))}
          </Title>
        </Center>

        {/*mobileView && (
          <Center className="w-100">
            <Text size={16} className={'text-center mb-4'}>
              {ReactHtmlParser(t('auth.our_services'))}
            </Text>
          </Center>
        )*/}

        <div className="w-full gap-4 flex flex-col justify-center items-center">
          <div className="w-full flex gap-4">
            <Button fullWidth color={'secondary'} radius={32} onClick={goToLogin}>
              {t('login')}
            </Button>

            <Button fullWidth radius={32} onClick={goToRegister}>
              {t('create_account')}
            </Button>
          </div>
          {/*<Button size="sm" color={'secondary'} radius={32} onClick={handleNew}>
            {t('start_without_login')}
          </Button>*/}
        </div>
      </Group>

      <LoginContainer hideForm role={role} onApprovedInfo={onApprovedInfo} />

      <AccountNotApprovedInfo visible={visible} onClose={close} />

      {/*mobileView && (
        <Center className="w-100">
          <div className={classes.infoBox}>
            <div className={classes.imageLayout}>
              <InfoBox width={355} height={304} />
            </div>
            <div className={classes.infoBoxHeader}>{ReactHtmlParser(t('auth.our_work'))}</div>
          </div>
        </Center>
      )*/}
      {mobileView && <SectionReviews showReviews={false} />}

      {role === ROLE.Client && (
        <>
          <Space h={'sm'} />

          <div className={classes.imgWrap}>
            <img src={cat} alt={'cat'} width={'140px'} />
          </div>
        </>
      )}
    </div>
  )
}
